
import axiosInstance from "lib/AxiosConfig";
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';

export const BOOKING_API_REDUCER_KEY = 'bookingApi';

export interface BookingDetailsRequestI {
    scheduledStartTime: string
    scheduledEndTime: string
    classification: string
}
export interface BookingDetailResponseI {
    bookingId: number
    currentStatusId: number
    currentStatus?: string
    customer: {
        id: number
        name: string
        color: string
    }
    device: {
        id: number
        name: string
        type: string
        trainingLimitation?: string
    }
    facility: {
        id: number
        name: string
    }
    scheduledStartTime: string
    scheduledEndTime: string
    actualStartTime: string
    actualEndTime: string
    instructors: {
        id: number
        name: string
    }[]
    crews: {
        id: number
        name: string
    }[]
    downTimeMinutes: number
    lostTimeMinutes: number
    simulatorType?: {
        id: number
        name: string
    }
    trainingType: {
        id: number
        name: string
    }
    classification: string
    notes?: string
    color?: string
}

export interface BookingDetailsResponseI extends Array<BookingDetailResponseI> { }

export interface BookingUpsertRequestI {
    bookingId?: number
    scheduledStartTime?: string
    scheduledEndTime?: string
    actualStartTime?: string
    actualEndTime?: string
    customerId?: number
    facilityId?: number
    deviceId?: number
    simulatorType?: number
    trainingId?: number
    instructors?: number[]
    crews?: number[]
    notes?: string
    status?: number
    currentStatus?: number
    downTimeMinutes?: number
    lostTimeMinutes?: number
}

export const bookingApi = createApi({
    reducerPath: BOOKING_API_REDUCER_KEY,
    keepUnusedDataFor: 60,
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Bookings'],
    endpoints: (builder) => ({
        getBookingDetails: builder.query<BookingDetailsResponseI, BookingDetailsRequestI>(
            {
                query: (params) => ({
                    url: '/admin/bookingDetails',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any) => {
                    // console.log("8507 getBookingDetails response", response);
                    const { data, success } = response;
                    if (success) {
                        return data;
                    } else {
                        return [];
                    }
                },
                providesTags: ['Bookings'],
            }
        ),
        upsertBooking: builder.mutation<any, BookingUpsertRequestI>(
            {
                query: (booking) => ({
                    url: '/admin/booking/upsert',
                    method: 'POST',
                    body: booking
                }),
                invalidatesTags: ['Bookings'],
            }),
    }),
});

export const findUsersByCustomerIdAndRoles = async (clientId: number | string, roleIds: number[]) => {
    const body = {
        customerId: clientId,
        isActive: 1,
        roleIds
    }
    const res = await axiosInstance.post("/admin/user/getUsersByCustomerIdAndRoles",
        body
    );
    console.log("8507 findUsersByClientIdAndRoles res", res);
    return await new Promise((resolve, reject) => {
        if (res.status === 200 && res.data.success) {
            resolve(res.data.data.map((u: any) => mapUserOptionData(u)));
        } else {
            console.error("8507 findUsersByClientIdAndRoles res error");
            reject(false);
        }
    });
}

type UserOption = {
    id: string
    name: string
}

const mapUserOptionData = (u: any) => {
    const user: UserOption = {
        id: u.userId,
        name: `${u.lastName || ''} ${u.firstName || ''}`,
    };
    return user;
}