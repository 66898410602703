
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQuery';

export const DASHBOARD_API_REDUCER_KEY = 'dashboardApi';

export interface DashboardAnalyticsRequestI {
    start: string
    end: string
    classification: string
    client: number
}

export interface DashboardAnalyticsDeviceI {
    deviceName: string
    classification?: string
    months: DashboardAnalyticsMonthsI[]
}

export interface DashboardAnalyticsMonthsI {
    month: string
    totalCompleted: number
    totalForecast: number
}

export interface DashboardAnalyticsI {
    year: string
    devices: DashboardAnalyticsDeviceI[]
}

export interface DashboardAnalyticsResponseI extends Array<DashboardAnalyticsI> { }

export const dashboardApi = createApi({
    reducerPath: DASHBOARD_API_REDUCER_KEY,
    keepUnusedDataFor: 60,
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getDashboardAnalytics: builder.query<DashboardAnalyticsResponseI, DashboardAnalyticsRequestI>(
            {
                query: (params) => ({
                    url: '/admin/dashboard',
                    method: 'GET',
                    params
                }),
                transformResponse: (response: any) => {
                    const { data, success } = response;
                    if (success) {
                        if (data && Array.isArray(data)) {
                            return data;
                        }
                    }
                    return [];
                },
            }
        ),
    }),
});